import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 | Lifespring Votes</title>
    </Helmet>
    <h1>NOT FOUND</h1>
  </Layout>
)

export default NotFoundPage
