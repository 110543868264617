import React from "react"
import { Header } from "./Header"

export const Layout: React.FC = props => (
  <>
    <Header />
    <main className="min-h-full bg-gray-50">
      <div className="h-full max-w-7xl mx-auto sm:px-6 lg:px-8 sm:pt-8 lg:pt-16">
        {props.children}
      </div>
    </main>
  </>
)
